/* eslint-disable react-refresh/only-export-components */
import type { ConnectProps } from '@/models/connect';
import type { SdkConfig } from '@/models/sdk';
import NoFoundPage from '@/pages/404';
import { getHistory, PageLoading } from '@propify-tenant-client/common';
import type { Update } from 'history';
import type { FC } from 'react';
import { lazy, Suspense, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router';
import { createHashRouter, RouterProvider } from 'react-router-dom';

const App = lazy(() => import('./pages/app'));
const Unit = lazy(() => import('./pages/unit'));

const Root: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useLayoutEffect(() => {
    if (
      location.pathname === getHistory().location.pathname &&
      location.search === getHistory().location.search &&
      location.hash === getHistory().location.hash
    ) {
      return;
    }
    getHistory().replace(location.pathname + location.search + location.hash);
  }, [location.pathname, location.search, location.hash]);

  useLayoutEffect(() => {
    return getHistory().listen((change: Update) => {
      if (
        location.pathname === change.location.pathname &&
        location.search === change.location.search &&
        location.hash === change.location.hash
      ) {
        return;
      }
      navigate(change.location.pathname + change.location.search + change.location.hash, {
        replace: true,
      });
    });
  }, [location.pathname, location.search, location.hash, navigate]);

  return <Outlet />;
};

const router = createHashRouter([
  {
    path: '/',
    element: <Root />,
    children: [
      {
        path: '/',
        element: (
          <Suspense fallback={<PageLoading />}>
            <App />
          </Suspense>
        ),
        errorElement: <NoFoundPage />,
      },
      {
        path: '/units/:id',
        element: (
          <Suspense fallback={<PageLoading />}>
            <Unit />
          </Suspense>
        ),
        errorElement: <NoFoundPage />,
      },
    ],
    errorElement: <NoFoundPage />,
  },
]);

type Props = ConnectProps & {
  config: Partial<SdkConfig>;
};

const AppRouter: FC<Props> = ({ config, dispatch }) => {
  useLayoutEffect(() => {
    dispatch({
      type: 'sdk/setConfig',
      payload: config,
    });
  }, []);

  return <RouterProvider router={router} />;
};

export default connect()(AppRouter);
