import type { ConnectProps, ConnectState } from '@/models/connect';
import { GAEvent } from '@/utils/utils';
import { isNumber, PageLoading } from '@propify-tenant-client/common';
import type { Unit } from '@propify-tenant-client/services';
import { UnitDetails, UnitNotFound } from '@propify-tenant-client/units';
import isEmpty from 'lodash/isEmpty';
import type { FC } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';

type Props = ReturnType<typeof mapStateToProps> & ConnectProps;

const UnitPage: FC<Props> = ({ unit, loading, config, dispatch, login }) => {
  const { id: unitId } = useParams();
  const id = Number(unitId);

  const onRegisterSubmit = () => {
    GAEvent('User', 'Register');
  };

  const onLoginSubmit = () => {
    GAEvent('User', 'Login');
  };

  useEffect(() => {
    if (!isNumber(id)) {
      return;
    }

    dispatch({
      type: 'unit/findById',
      payload: id,
    });
  }, [id]);

  if (loading) {
    return <PageLoading />;
  }

  if (isEmpty(unit)) {
    return <UnitNotFound />;
  }

  return (
    <UnitDetails
      unit={unit as Unit}
      source={config?.trackingMap?.source}
      onLoginSubmit={onLoginSubmit}
      onRegisterSubmit={onRegisterSubmit}
      login={login}
    />
  );
};

const mapStateToProps = (state: ConnectState) => ({
  config: state.sdk.config,
  unit: state.unit.unit,
  loading: state.loading.effects['unit/findById'],
  login: state.account?.login,
});

const UnitPageWithProps = connect(mapStateToProps)(UnitPage);

export default UnitPageWithProps;
