import { makeStyles, Typography } from '@/utils/material';

const useStyles = makeStyles({
  empty: {
    textAlign: 'center',
    margin: '4em 1em',
  },
});

export const NoResults = () => {
  const classes = useStyles();
  return (
    <Typography variant="h5" className={classes.empty}>
      Sorry, there are currently no homes available for lease in this area that meet your filter
      criteria.
    </Typography>
  );
};
