import type { SdkConfig } from '@/models/sdk';
import AppRouter from '@/sdk/router';
import { persistor, store } from '@/sdk/store';
import { createTheme, jssPreset, StylesProvider, ThemeProvider } from '@/utils/material';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { PageLoading, setHistory } from '@propify-tenant-client/common';
import {
  EnvironmentService,
  getEnvironment,
  initializeApi,
  SentryService,
  setOrganizationToken,
} from '@propify-tenant-client/services';
import { Big } from 'big.js';
import { createHashHistory } from 'history';
import { create } from 'jss';
import set from 'lodash/set';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { Provider } from 'react-redux';
// @ts-ignore
import retargetEvents from 'react-shadow-dom-retarget-events';
import { PersistGate } from 'redux-persist/integration/react';

Big.RM = 2;
Big.DP = 10;

const environment = getEnvironment();

initializeApi();

const history = createHashHistory();
setHistory(history);
let hash = window.location.hash || '/';
if (hash.startsWith('#')) {
  hash = hash.substring(1);
}
history.replace(hash || '/');

const initializeAnalytics = (trackers: ReactGA.Tracker[]) => {
  const defaultTracker: ReactGA.Tracker = {
    trackingId: 'UA-180474954-3',
    gaOptions: {
      name: 'sdkTracker',
    },
  };

  const allTrackers = [...trackers, defaultTracker];

  const trackerNames = allTrackers
    .map((t) => t.gaOptions?.name)
    .filter((name) => typeof name === 'string') as string[];

  if (environment !== 'local') {
    ReactGA.initialize(allTrackers);

    history.listen((update) => {
      const location = update.location;
      ReactGA.pageview(location.pathname + location.search, trackerNames);
    });
  }
};

const defaultConfig: SdkConfig = {
  elementSelector: '#phoenix-sdk-root',
  debug: false,
  searchOnMount: true,
  shadowMainHeight: '100vh',
  theme: {
    typography: {
      fontSize: 14,
      fontFamily:
        '-apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    },
  },
};

const parseTracking = (tracking?: string) => {
  if (!tracking) {
    return {};
  }

  // noinspection SuspiciousTypeOfGuard
  if (typeof tracking !== 'string') {
    throw new TypeError('tracking must be a string');
  }

  const values = {};

  tracking.split('|').forEach((entry) => {
    const [key, value] = entry.split('=');
    if (key && value) {
      set(values, key, value);
    }
  });

  return values;
};

(window as any).phoenixSDK = {
  init(config: SdkConfig = {}) {
    if (!config.token) {
      throw new Error('Invalid Token provided');
    }

    (window as any).propifySdk = true;
    setOrganizationToken(config.token);

    EnvironmentService.init();
    SentryService.init({ integrations: [] });
    const finalConfig = {
      ...defaultConfig,
      ...config,
      trackingMap: parseTracking(config.tracking),
    };
    initializeAnalytics(finalConfig.trackers || []);

    if (finalConfig.shadowRoot) {
      const shadowRoot = document.querySelector(finalConfig.elementSelector!)!.shadowRoot!;

      const mountPoint = document.createElement('span');
      const reactRoot = shadowRoot.appendChild(mountPoint);

      const portalContainer = reactRoot.appendChild(document.createElement('div'));
      portalContainer.className = 'propify-shadow';

      const jss = create({
        ...jssPreset(),
        insertionPoint: reactRoot,
      });

      const theme = createTheme(finalConfig.theme, {
        props: {
          MuiPopover: {
            container: (): any => {
              return portalContainer;
            },
          },
          MuiDialog: {
            container: (): any => {
              return portalContainer;
            },
            disableEnforceFocus: true,
          },
          MuiSelect: {
            container: (): any => {
              return portalContainer;
            },
          },
          MuiTooltip: {
            PopperProps: {
              // do not forget to nest it here, Tooltip does not have portal props because its composed with Popper
              container: portalContainer,
              disablePortal: true,
            },
          },
          MuiModal: {
            container: () => {
              return portalContainer;
            },
            disablePortal: true,
          },
          MuiMenu: {
            container: () => {
              return portalContainer;
            },
          },
          MuiMenuItem: {
            container: () => {
              return portalContainer;
            },
          },
          MuiToolbar: {
            container: () => {
              return portalContainer;
            },
          },
          MuiDataGrid: {
            componentsProps: {
              columnMenu: portalContainer,
              menu: portalContainer,
              columnsPanel: portalContainer,
              filterPanel: portalContainer,
              panel: portalContainer,
              preferencesPanel: portalContainer,
            },
          },
        },
      });

      const styleRoot = document.createElement('style');
      styleRoot.setAttribute(
        'textContent',
        `
      .propify-shadow {
        line-height: initial;
        font-size: initial;
        font-family: ${theme.typography.fontFamily};
      }
      `,
      );
      reactRoot.appendChild(styleRoot);

      const emotionRoot = document.createElement('style');
      reactRoot.appendChild(emotionRoot);

      const cache = createCache({
        key: 'css',
        prepend: true,
        container: emotionRoot,
      });

      ReactDOM.render(
        <StrictMode>
          <Provider store={store}>
            <PersistGate loading={<PageLoading />} persistor={persistor}>
              <StylesProvider jss={jss}>
                <ThemeProvider theme={theme}>
                  <CacheProvider value={cache}>
                    <AppRouter config={finalConfig} />
                  </CacheProvider>
                </ThemeProvider>
              </StylesProvider>
            </PersistGate>
          </Provider>
        </StrictMode>,
        portalContainer,
      );

      retargetEvents(shadowRoot);
    } else {
      const theme = createTheme(finalConfig.theme);

      ReactDOM.render(
        <Provider store={store}>
          <PersistGate loading={<PageLoading />} persistor={persistor}>
            <ThemeProvider theme={theme}>
              <AppRouter config={finalConfig} />
            </ThemeProvider>
          </PersistGate>
        </Provider>,
        document.querySelector(finalConfig.elementSelector!),
      );
    }

    // eslint-disable-next-line no-console
    console.log('> PhoenixSDK initialized');
  },
};

// eslint-disable-next-line no-console
console.log('> PhoenixSDK loaded');
